import './FormCompliance.css'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { getAllergenAction } from 'actions/allergenActions'
import { clearCompliance, updateComplianceAction } from 'actions/complianceActions'
import { formatDateWithPadding, getSessionInfo, isKontamiExist, reRenderMessageSplitDot } from 'commons/utilities'
import { CustomCheckbox, ModalConfirm, ModalConfirmNavigation } from 'components/commons'
import { MessageErrorDialog, ProgressDialog } from 'components/commons'
import {
  GET_ALLERGEN_LIST_FAILED,
  GET_ALLERGEN_LIST_REQUEST,
  GET_ALLERGEN_LIST_SUCCESS,
} from 'constants/actionTypes/allergenActionTypes'
import {
  UPDATE_COMPLIANCE_FAILED,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_SUCCESS,
} from 'constants/actionTypes/complianceActionTypes'
import { COMPLIANCE_TYPE, FLAG_BOOLEAN, LIST_COMPLIANCE_TYPE } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Allergen from './Allergen/Allergen.jsx'

function FormCompliance() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const useStyles = makeStyles(() => ({
    customCheckboxAllergen: {
      '& .radio': {
        padding: 0,
        marginRight: '10px',
        width: '20px',
      },
      '& .radio .radio__container > span': {
        padding: 0,
      },
      '& .radio .radio__container input': {
        width: '20px',
      },
    },
  }))
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [allergenCodeAfter, setAllergenCodeAfter] = useState(FLAG_BOOLEAN.TRUE)
  const [kontamiCodeAfter, setKontamiCodeAfter] = useState(FLAG_BOOLEAN.TRUE)
  const [dataCompliance, setDataCompliance] = useState({})
  const [tradeMark, setTradeMark] = useState(FLAG_BOOLEAN.FALSE)
  const [confirm, setConfirm] = useState(FLAG_BOOLEAN.FALSE)
  const [approval, setApproval] = useState(FLAG_BOOLEAN.FALSE)
  const [dateApproval, setDateApproval] = useState('')
  const [loading, setLoading] = useState(false)
  const [listAllergen, setListAllergen] = useState([])
  const listAllergenReducer = useSelector((state) => state.listAllergen || {})
  const [listAllergenArray, setListAllergenArray] = useState({})
  const [allergenCodeUpdate, setAllergenCodeUpdate] = useState({})
  const [kontamiCodeUpdate, setKontamiCodeUpdate] = useState({})
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const listComplianceUpdate = useSelector((state) => state.updateCompliance || {})
  const [messageError, setMessageError] = useState()
  const [defaultAllergenCode, setDefaultAllergenCode] = useState({})
  const [defaultKontamiCode, setDefaultKontamiCode] = useState({})
  // const [approvalItem, setApprovalItem] = useState(FLAG_BOOLEAN.FALSE)
  const [searchInput, setSearchInput] = useState('')
  const [checkboxLast, setCheckboxLast] = useState(false)
  const [enableLastCheckbox, setEnableLastCheckbox] = useState(false)
  const [dtWeek, setDtWeek] = useState('')
  const [listDtWeek, setListDtWeek] = useState([])
  const [canSubmit, setCanSubmit] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)

  let lastArrayIndex = 0

  useEffect(() => {
    dispatch(getAllergenAction())
  }, [dispatch])

  useEffect(() => {
    const { data, approval = '', searchText = '', dtWeek, listDtWeek } = location?.state

    if (dtWeek) {
      setDtWeek(dtWeek)
    }

    if (listDtWeek) {
      setListDtWeek(listDtWeek)
    }

    if (data.KB_APPR === FLAG_BOOLEAN.TRUE) setCheckboxLast(true)
    if (Object.keys(data).length > 0) {
      setTradeMark(data.KB_CMPL)
      setConfirm(data.KB_KINFO)
      setApproval(data.KB_APPR)
      setAllergenCodeAfter(data.KB_ARGSET?.AF)
      setAllergenCodeUpdate(data.CD_ARG)
      if (
        isKontamiExist(sysInfo?.MP_COMP?.KB_CONTAMI) &&
        data.hasOwnProperty('CD_CNTM') &&
        data.hasOwnProperty('KB_CNTMSET')
      ) {
        setKontamiCodeUpdate(data.CD_CNTM)
        setKontamiCodeAfter(data.KB_CNTMSET?.AF)
      }
      setDateApproval(data.hasOwnProperty('DT_APPR') ? formatDateWithPadding(data.DT_APPR, 'yyyy-MM-dd') : '')
      setDataCompliance(data)
    }

    // if (approval) setApprovalItem(approval)
    if (searchText) setSearchInput(searchText)
    if (approval === FLAG_BOOLEAN.FALSE) {
      setCheckboxLast(false)
    } else if (approval === FLAG_BOOLEAN.TRUE) {
      setCheckboxLast(true)
    }
  }, [location])

  useEffect(() => {
    if (listAllergenReducer.listAllergen.length >= 0) {
      setListAllergen([...listAllergenReducer.listAllergen])
    }

    setLoading(listAllergenReducer.loading || listComplianceUpdate.loading)
    switch (listAllergenReducer.type) {
      case GET_ALLERGEN_LIST_REQUEST:
        break
      case GET_ALLERGEN_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listAllergenReducer.message)
        }
        break
      case GET_ALLERGEN_LIST_SUCCESS:
        break
      default:
        return
    }
    switch (listComplianceUpdate.type) {
      case UPDATE_COMPLIANCE_REQUEST:
        break
      case UPDATE_COMPLIANCE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listComplianceUpdate.message)
        }
        break
      case UPDATE_COMPLIANCE_SUCCESS:
        dispatch(clearCompliance())
        history.push({
          pathname: '/customer/compliance-check',
          state: {
            approval: location.state.approvalItem,
            searchText: searchInput,
            dtWeek: dtWeek,
            listDtWeek: listDtWeek,
          },
        })
        break
      default:
        break
    }
  }, [listAllergenReducer, listComplianceUpdate])
  useEffect(() => {
    if (confirm === FLAG_BOOLEAN.TRUE && tradeMark === FLAG_BOOLEAN.TRUE) {
      setEnableLastCheckbox(false)
    } else {
      setEnableLastCheckbox(true)
    }
  }, [kontamiCodeAfter, allergenCodeAfter, confirm, tradeMark])
  useEffect(() => {
    if (listAllergen.length > 0) {
      splitAllergenArray()
      const initalAllergenCode = listAllergen.reduce((prev, curr) => {
        let currentCode = curr.CD_ALRG
        if (dataCompliance.CD_ARG.hasOwnProperty(currentCode)) {
          prev[currentCode] = { BF: '', AF: '' }
          prev[currentCode]['BF'] = dataCompliance.CD_ARG[currentCode].BF
          prev[currentCode]['AF'] = dataCompliance.CD_ARG[currentCode].AF
        } else {
          prev[currentCode] = { BF: FLAG_BOOLEAN.FALSE, AF: FLAG_BOOLEAN.FALSE }
        }

        return prev
      }, {})

      const initalKontamiCode = listAllergen.reduce((prev, curr) => {
        let currentCode = curr.CD_ALRG
        if (dataCompliance.hasOwnProperty('CD_CNTM') && dataCompliance.CD_CNTM.hasOwnProperty(currentCode)) {
          prev[currentCode] = { BF: '', AF: '' }
          prev[currentCode]['BF'] = dataCompliance.CD_CNTM[currentCode].BF
          prev[currentCode]['AF'] = dataCompliance.CD_CNTM[currentCode].AF
        } else {
          prev[currentCode] = { BF: FLAG_BOOLEAN.FALSE, AF: FLAG_BOOLEAN.FALSE }
        }

        return prev
      }, {})
      setDefaultKontamiCode(initalKontamiCode)
      setDefaultAllergenCode(initalAllergenCode)
    }
  }, [listAllergen])

  const getDefaultDisplayListAllergen = (listActiveCode = [], type = '') => {
    const modifiedAllergenArr = JSON.parse(JSON.stringify(listAllergenArray))
    const allergenMap = new Map()
    Object.keys(modifiedAllergenArr).forEach((key) => {
      allergenMap.set(key, modifiedAllergenArr[key])
    })

    let allergenMapValue = allergenMap.values()

    let allergenMapArray = [],
      index = 0
    allergenMapArray = allergenMapValue.next().value

    while (allergenMapArray) {
      let arrayResult = allergenMapArray.map((el) => {
        if (type === COMPLIANCE_TYPE.ALLERGEN && listActiveCode.find((code) => code === el.CD_ALRG)) {
          return {
            ...el,
            DISABLE_AFTER: false,
          }
        }
        if (type === COMPLIANCE_TYPE.KONTAMI && listActiveCode.find((code) => code === el.CD_ALRG)) {
          return {
            ...el,
            KONTAMI_DISABLE_AFTER: false,
          }
        }

        return { ...el }
      })

      modifiedAllergenArr[index] = [...arrayResult]
      index++
      allergenMapArray = allergenMapValue.next().value
    }
    return modifiedAllergenArr
  }

  const getDefaultActiveCodeAfter = (defaultCode = {}) => {
    return Object.keys(defaultCode).reduce((prev, current) => {
      if (defaultCode[current].AF === FLAG_BOOLEAN.TRUE) {
        prev.push(current)
      }
      return prev
    }, [])
  }

  useEffect(() => {
    // Choose アレルゲンなし || 対象外 || 未設定 radio checkbox
    if (allergenCodeAfter !== FLAG_BOOLEAN.TRUE) {
      // 1. Change all alleregen code AF update atributes to 0
      const modifiedAllergen = JSON.parse(JSON.stringify(allergenCodeUpdate))
      Object.keys(modifiedAllergen).forEach((code) => {
        modifiedAllergen[code]['AF'] = FLAG_BOOLEAN.FALSE
      })
      setAllergenCodeUpdate(modifiedAllergen)
      // 2. Change all alleregen code AF display to disable
      const displayAllergen = JSON.parse(JSON.stringify(listAllergenArray))
      for (const value of Object.values(displayAllergen)) {
        value.forEach((val) => {
          val.DISABLE_AFTER = true
        })
      }
      setListAllergenArray(displayAllergen)
    }
    // Choose アレルゲン（訂正後）radio checkbox
    if (allergenCodeAfter === FLAG_BOOLEAN.TRUE && canSubmit) {
      // 1. Change all alleregen code AF display to default
      setAllergenCodeUpdate(defaultAllergenCode)
      // 2. Change all alleregen code AF update sent to update API to default
      const listActiveCode = getDefaultActiveCodeAfter(defaultAllergenCode)
      const listDefaultAllergen = getDefaultDisplayListAllergen(listActiveCode, COMPLIANCE_TYPE.ALLERGEN)
      setListAllergenArray(listDefaultAllergen)
    }
  }, [allergenCodeAfter])

  useEffect(() => {
    if (kontamiCodeAfter !== FLAG_BOOLEAN.TRUE && isKontamiExist(sysInfo?.MP_COMP?.KB_CONTAMI)) {
      // 1. Change all kontami code AF update atributes to 0
      const modifiedKontami = JSON.parse(JSON.stringify(kontamiCodeUpdate))
      Object.keys(modifiedKontami).forEach((code) => {
        modifiedKontami[code]['AF'] = FLAG_BOOLEAN.FALSE
      })
      setKontamiCodeUpdate(modifiedKontami)
      // 2. Change all kontami code AF display to disable
      const displayKontami = JSON.parse(JSON.stringify(listAllergenArray))
      for (const value of Object.values(displayKontami)) {
        value.forEach((val) => {
          val.KONTAMI_DISABLE_AFTER = true
        })
      }
      setListAllergenArray(displayKontami)
    }

    // Choose コンタミ （訂正後）radio checkbox
    if (kontamiCodeAfter === FLAG_BOOLEAN.TRUE && canSubmit) {
      // 1. Change all kontami code AF display to default
      setKontamiCodeUpdate(defaultKontamiCode)
      // 2. Change all kontami code AF sent to update API to default
      const listActiveCode = getDefaultActiveCodeAfter(defaultKontamiCode)
      const listDefaultKontami = getDefaultDisplayListAllergen(listActiveCode, COMPLIANCE_TYPE.KONTAMI)
      setListAllergenArray(listDefaultKontami)
    }
  }, [kontamiCodeAfter])

  const splitAllergenArray = () => {
    let splitedArrAllergen = {}
    let index = 0
    let arrayDisableInfo = {}
    const arrClassification1 = listAllergen.filter((allergen) => allergen.KB_SPEC === FLAG_BOOLEAN.TRUE)
    const arrClassification0 = listAllergen.filter((allergen) => allergen.KB_SPEC === FLAG_BOOLEAN.FALSE)
    const numberArrSplit = Math.ceil(arrClassification0.length / 10) + Math.ceil(arrClassification1.length / 10)
    const sortedListAllergen = listAllergen.sort((a, b) => b.KB_SPEC.localeCompare(a.KB_SPEC))
    for (let i = 0; i < numberArrSplit; i++) {
      splitedArrAllergen[i] = []
    }
    sortedListAllergen.forEach((alleregen, allergenIndex) => {
      if (splitedArrAllergen[index].length === 10 || allergenIndex === arrClassification1.length) {
        index++
      }
      if (allergenIndex === arrClassification1.length) {
        lastArrayIndex = index
      }

      arrayDisableInfo = getDisableInfo(alleregen)
      splitedArrAllergen[index].push(arrayDisableInfo)
    })

    setListAllergenArray(splitedArrAllergen)
  }

  const getDisableInfo = (allergen) => {
    let allergenInfo = { ...allergen }
    const key = ['CD_ARG', 'CD_CNTM']

    key.forEach((k, index) => {
      if (dataCompliance.hasOwnProperty(k) && dataCompliance[k]?.hasOwnProperty(allergen.CD_ALRG)) {
        if (dataCompliance[k][allergen.CD_ALRG]['BF'] === FLAG_BOOLEAN.TRUE) {
          allergenInfo[`${index === 0 ? 'DISABLE_BEFORE' : 'KONTAMI_DISABLE_BEFORE'}`] = false
        } else {
          allergenInfo[`${index === 0 ? 'DISABLE_BEFORE' : 'KONTAMI_DISABLE_BEFORE'}`] = true
        }

        if (dataCompliance[k][allergen.CD_ALRG]['AF'] === FLAG_BOOLEAN.TRUE) {
          allergenInfo[`${index === 0 ? 'DISABLE_AFTER' : 'KONTAMI_DISABLE_AFTER'}`] = false
        } else {
          allergenInfo[`${index === 0 ? 'DISABLE_AFTER' : 'KONTAMI_DISABLE_AFTER'}`] = true
        }
      } else {
        allergenInfo[`${index === 0 ? 'DISABLE_BEFORE' : 'KONTAMI_DISABLE_BEFORE'}`] = true
        allergenInfo[`${index === 0 ? 'DISABLE_AFTER' : 'KONTAMI_DISABLE_AFTER'}`] = true
      }
    })

    return allergenInfo
  }

  const handleChangeAllergenCodeAF = (e) => {
    setIsBlocked(true)
    setCanSubmit(true)
    setAllergenCodeAfter(e.target.value)
  }

  const handleChangeKontamiCodeAF = (e) => {
    setIsBlocked(true)
    setCanSubmit(true)
    setKontamiCodeAfter(e.target.value)
  }

  const handleChangeCheckBox = (name, value) => {
    setIsBlocked(true)
    setCanSubmit(true)
    if (name === 'KB_CMPL') {
      setTradeMark((value + 0).toString())
      return
    }
    if (name === 'KB_KINFO') {
      setConfirm((value + 0).toString())
      return
    }
    if (name === 'KB_APPR' && tradeMark === FLAG_BOOLEAN.TRUE && confirm === FLAG_BOOLEAN.TRUE) {
      setApproval((value + 0).toString())
      return
    }
  }

  const handleCancelButton = () => {
    history.push({
      pathname: '/customer/compliance-check',
      state: {
        approval: location.state.approvalItem,
        searchText: searchInput,
        dtWeek: dtWeek,
        listDtWeek: listDtWeek,
      },
    })
  }

  const handleAllergenClick = (allergen, arrIndex) => {
    setIsBlocked(true)
    if (allergenCodeAfter === FLAG_BOOLEAN.TRUE) {
      setCanSubmit(true)
      const modifiedArr = JSON.parse(JSON.stringify(listAllergenArray))
      const allergenUpdate = JSON.parse(JSON.stringify(allergenCodeUpdate))
      // 1. change disable AF info of allergen code display in screen
      let modifiedIndex = modifiedArr[arrIndex].findIndex((data) => data.CD_ALRG === allergen.CD_ALRG)
      if (modifiedIndex >= 0) {
        modifiedArr[arrIndex][modifiedIndex].DISABLE_AFTER = !modifiedArr[arrIndex][modifiedIndex].DISABLE_AFTER
        allergen.DISABLE_AFTER = !allergen.DISABLE_AFTER
      }

      // 1. change disable AF info of allergen code pass to Update API
      Object.keys(allergenUpdate).forEach((code) => {
        if (allergen.CD_ALRG === code) {
          if (allergen.DISABLE_AFTER) {
            allergenUpdate[code].AF = FLAG_BOOLEAN.FALSE
          } else {
            allergenUpdate[code].AF = FLAG_BOOLEAN.TRUE
          }
        }
        if (!allergenUpdate.hasOwnProperty(allergen.CD_ALRG)) {
          allergenUpdate[allergen.CD_ALRG] = {
            AF: FLAG_BOOLEAN.TRUE,
            BF: FLAG_BOOLEAN.FALSE,
          }
        }
      })

      if (
        !dataCompliance.CD_ARG.hasOwnProperty(allergen.CD_ALRG) &&
        allergenUpdate[allergen.CD_ALRG]['AF'] === FLAG_BOOLEAN.FALSE
      ) {
        delete allergenUpdate[allergen.CD_ALRG]
      }

      setAllergenCodeUpdate(allergenUpdate)
      setListAllergenArray(modifiedArr)
    }
  }

  const handleKontamiClick = (kontami, arrIndex) => {
    setIsBlocked(true)
    if (kontamiCodeAfter === FLAG_BOOLEAN.TRUE) {
      setCanSubmit(true)
      const modifiedKontamiArr = JSON.parse(JSON.stringify(listAllergenArray))
      const kontamiUpdate = JSON.parse(JSON.stringify(kontamiCodeUpdate))
      // 1. change disable AF info of kontami code display in screen
      let modifiedIndex = modifiedKontamiArr[arrIndex].findIndex((data) => data.CD_ALRG === kontami.CD_ALRG)
      if (modifiedIndex >= 0) {
        modifiedKontamiArr[arrIndex][modifiedIndex].KONTAMI_DISABLE_AFTER =
          !modifiedKontamiArr[arrIndex][modifiedIndex].KONTAMI_DISABLE_AFTER
        kontami.KONTAMI_DISABLE_AFTER = !kontami.KONTAMI_DISABLE_AFTER
      }

      // 1. change disable AF info of kontami code pass to Update API
      Object.keys(kontamiUpdate).forEach((code) => {
        if (kontami.CD_ALRG === code) {
          if (kontami.KONTAMI_DISABLE_AFTER) {
            kontamiUpdate[code].AF = FLAG_BOOLEAN.FALSE
          } else {
            kontamiUpdate[code].AF = FLAG_BOOLEAN.TRUE
          }
        }
        if (!kontamiUpdate.hasOwnProperty(kontami.CD_ALRG)) {
          kontamiUpdate[kontami.CD_ALRG] = {
            AF: FLAG_BOOLEAN.TRUE,
            BF: FLAG_BOOLEAN.FALSE,
          }
        }
      })

      if (
        !dataCompliance.CD_CNTM.hasOwnProperty(kontami.CD_ALRG) &&
        kontamiUpdate[kontami.CD_ALRG]['AF'] === FLAG_BOOLEAN.FALSE
      ) {
        delete kontamiUpdate[kontami.CD_ALRG]
      }

      setKontamiCodeUpdate(kontamiUpdate)
      setListAllergenArray(modifiedKontamiArr)
    }
  }

  const isUpdateData = (obj) => {
    let canSubmit = false

    for (let item = 0; item < Object.values(obj).length; item++) {
      if (Object.values(obj)[item]['AF'] !== FLAG_BOOLEAN.FALSE) {
        canSubmit = true
        break
      }
    }
    return canSubmit
  }
  const submitData = () => {
    setIsBlocked(false)
    let isAllergenAfterChange = false,
      isKontamiAfterChange = false,
      editInfo = getEditInformation(isAllergenAfterChange, isKontamiAfterChange)
    let dataUpdate = {
      CD_CUST: dataCompliance.CD_CUST,
      KY_SORT: dataCompliance.KY_SORT,
      // KB_CMPL: tradeMark,
      // KB_KINFO: confirm,
      // KB_APPR: approval,
      KB_APPR: approval,
      DT_APPR: formatDateWithPadding(new Date(), 'yyyy-MM-dd'),
      CD_ARG: allergenCodeUpdate,
      KB_ARGSET: {
        BF: dataCompliance?.KB_ARGSET?.BF || '',
        AF: allergenCodeAfter,
      },
    }

    if (isKontamiExist(sysInfo?.MP_COMP?.KB_CONTAMI)) {
      dataUpdate = {
        ...dataUpdate,
        CD_CNTM: kontamiCodeUpdate,
        KB_CNTMSET: {
          BF: dataCompliance?.KB_CNTMSET?.BF || '',
          AF: kontamiCodeAfter,
        },
      }
    }

    if (editInfo === FLAG_BOOLEAN.TRUE) {
      dataUpdate = { ...dataUpdate, KB_EDIT: editInfo }
    }
    if (sysInfo?.MP_COMP?.KB_SYOHYO === FLAG_BOOLEAN.TRUE) {
      dataUpdate = { ...dataUpdate, KB_CMPL: tradeMark }
    }
    if (sysInfo?.MP_COMP?.KB_KANNAI === FLAG_BOOLEAN.TRUE) {
      dataUpdate = { ...dataUpdate, KB_KINFO: confirm }
    }
    dispatch(updateComplianceAction(dataUpdate))
    setShowModal(false)
  }

  const toggleLastCheckbox = () => {
    if (checkboxLast === true) {
      setApproval(FLAG_BOOLEAN.FALSE)
      setCheckboxLast(false)
    } else {
      setApproval(FLAG_BOOLEAN.TRUE)
      setCheckboxLast(true)
    }
  }

  const getEditInformation = (isAllergenAfterChange, isKontamiAfterChange) => {
    if (dataCompliance.KB_CMPL !== tradeMark) {
      return FLAG_BOOLEAN.TRUE
    }
    if (dataCompliance.KB_KINFO !== confirm) {
      return FLAG_BOOLEAN.TRUE
    }

    if (dataCompliance.KB_ARGSET?.AF !== allergenCodeAfter) {
      return FLAG_BOOLEAN.TRUE
    } else {
      for (const value of Object.values(listAllergenArray)) {
        for (let i = 0; i < value.length; i++) {
          if (
            (value[i].DISABLE_AFTER === false && defaultAllergenCode[value[i].CD_ALRG].AF === FLAG_BOOLEAN.FALSE) ||
            (value[i].DISABLE_AFTER === true && defaultAllergenCode[value[i].CD_ALRG].AF === FLAG_BOOLEAN.TRUE)
          ) {
            isAllergenAfterChange = true
          }
        }
      }
    }

    if (dataCompliance.KB_CNTMSET?.AF !== kontamiCodeAfter) {
      return FLAG_BOOLEAN.TRUE
    } else {
      for (const value of Object.values(listAllergenArray)) {
        for (let i = 0; i < value.length; i++) {
          if (
            (value[i].KONTAMI_DISABLE_AFTER === false &&
              defaultKontamiCode[value[i].CD_ALRG].AF === FLAG_BOOLEAN.FALSE) ||
            (value[i].KONTAMI_DISABLE_AFTER === true && defaultKontamiCode[value[i].CD_ALRG].AF === FLAG_BOOLEAN.TRUE)
          ) {
            isKontamiAfterChange = true
          }
        }
      }
    }

    if (isAllergenAfterChange || isKontamiAfterChange) {
      return FLAG_BOOLEAN.TRUE
    }

    return FLAG_BOOLEAN.FALSE
  }

  const handleSubmit = () => {
    if (
      (allergenCodeAfter === FLAG_BOOLEAN.TRUE && isUpdateData(allergenCodeUpdate) === false) ||
      (Object.keys(kontamiCodeUpdate).length > 0 &&
        isUpdateData(kontamiCodeUpdate) === false &&
        kontamiCodeAfter === FLAG_BOOLEAN.TRUE &&
        isKontamiExist(sysInfo?.MP_COMP?.KB_CONTAMI))
    ) {
      setMessageError('アレルゲン名を選んでください。')
    } else if (!allergenCodeAfter || !kontamiCodeAfter) {
      setMessageError('アレルゲン名を選んでください。')
    } else {
      setShowModal(true)
    }
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('compliance.msgConfirmNavigate.line1')} </div><div>
  ${t('compliance.msgConfirmNavigate.line2')}</div></div>`

  return (
    <>
      <ModalConfirmNavigation title={confirmNavigationMessage} isBlocked={isBlocked} searchInput={searchInput} />
      <ModalConfirm
        title={reRenderMessageSplitDot(t('compliance.lblConfirmUpdate.line1'), t('compliance.lblConfirmUpdate.line2'))}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}

      {loading ? (
        <ProgressDialog label={t('portal.loading')} />
      ) : (
        <div className="form-compliance-wrapper">
          <div className="form-compliance-container">
            {/* Product Code */}
            <div className="product-code-container">
              <div className="product-code-title title-common">{t('compliance.lblProductCode')}</div>
              <div className="product-code-content content-common">{dataCompliance.CD_SYO}</div>
            </div>
            {/* Product Name Code */}

            <div className="product-name-container">
              <div className="product-name-title title-common">{t('compliance.lblProductName')}</div>
              <div className="product-name-content content-common">{dataCompliance.NM_SYO}</div>
            </div>
            {/* Supplier Code */}
            <div className="supplier-code-container">
              <div className="supplier-code-title title-common">{t('compliance.lblSupplierCode')}</div>
              <div className="supplier-code-content content-common">{dataCompliance.CD_HAT}</div>
            </div>

            {/* Supplier Name Code */}
            <div className="supplier-name-container">
              <div className="supplier-name-title title-common">{t('compliance.lblSupplierName')}</div>
              <div className="supplier-name-content content-common">{dataCompliance.NM_HAT}</div>
            </div>

            <div style={{ marginBottom: '20px' }}></div>
          </div>

          {/* ======================= ALLERGEN BEFORE START ========================= */}
          <Allergen
            customClass={classes.customCheckboxAllergen}
            title={t('compliance.lblAllergenAfter')}
            radioValue={dataCompliance.KB_ARGSET?.BF}
            listAllergenArray={listAllergenArray}
            dataCompliance={dataCompliance}
            lastArrayIndex={lastArrayIndex}
            lblNotHaveAllergen={t('compliance.lblNotHaveAllergen')}
            lblAllergenNoApply={t('compliance.lblAllergenNoApply')}
            lblAllerenNotSet={t('compliance.lblAllerenNotSet')}
            type={COMPLIANCE_TYPE.ALLERGEN}
            complianceType={LIST_COMPLIANCE_TYPE.ALLERGEN_BEFORE}
          />
          {/* ======================= ALLERGEN BEFORE END ========================= */}

          {/* ======================= ALLERGEN AFTER START ========================= */}
          <Allergen
            customClass={classes.customCheckboxAllergen}
            title={t('compliance.lblAllergenBefore')}
            radioValue={allergenCodeAfter}
            allergenBefore={false}
            listAllergenArray={listAllergenArray}
            dataCompliance={dataCompliance}
            lastArrayIndex={lastArrayIndex}
            handleClick={handleAllergenClick}
            lblNotHaveAllergen={t('compliance.lblNotHaveAllergen')}
            lblAllergenNoApply={t('compliance.lblAllergenNoApply')}
            lblAllerenNotSet={t('compliance.lblAllerenNotSet')}
            allergenCodeAfter={allergenCodeAfter}
            type={COMPLIANCE_TYPE.ALLERGEN}
            handleChangeCodeAF={handleChangeAllergenCodeAF}
            before={checkboxLast}
            complianceType={LIST_COMPLIANCE_TYPE.ALLERGEN_AFTER}
          />

          {/* ======================= ALLERGEN AFTER END ========================= */}

          {sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.TRUE ? (
            <>
              {/* ======================= KONTAMI BEFORE START ========================= */}
              <Allergen
                customClass={classes.customCheckboxAllergen}
                title={t('compliance.lblKontamiAfter')}
                radioValue={dataCompliance.KB_CNTMSET?.BF}
                listAllergenArray={listAllergenArray}
                dataCompliance={dataCompliance}
                lastArrayIndex={lastArrayIndex}
                lblNotHaveAllergen={t('compliance.lblNotHaveKontami')}
                lblAllergenNoApply={t('compliance.lblKontamiNoApply')}
                lblAllerenNotSet={t('compliance.lblKontamiNotSet')}
                type={COMPLIANCE_TYPE.KONTAMI}
                complianceType={LIST_COMPLIANCE_TYPE.KONTAMI_BEFORE}
              />

              {/* ======================= KONTAMI BEFORE END ========================= */}

              {/* ======================= KONTAMI AFTER START ========================= */}

              <Allergen
                customClass={classes.customCheckboxAllergen}
                title={t('compliance.lblKontamiBefore')}
                radioValue={kontamiCodeAfter}
                kontamiBefore={false}
                listAllergenArray={listAllergenArray}
                dataCompliance={dataCompliance}
                lastArrayIndex={lastArrayIndex}
                handleClick={handleKontamiClick}
                lblNotHaveAllergen={t('compliance.lblNotHaveKontami')}
                lblAllergenNoApply={t('compliance.lblKontamiNoApply')}
                lblAllerenNotSet={t('compliance.lblKontamiNotSet')}
                kontamiCodeAfter={kontamiCodeAfter}
                type={COMPLIANCE_TYPE.KONTAMI}
                handleChangeCodeAF={handleChangeKontamiCodeAF}
                before={checkboxLast}
                complianceType={LIST_COMPLIANCE_TYPE.KONTAMI_AFTER}
              />

              {/* ======================= KONTAMI AFTER END ========================= */}
            </>
          ) : (
            <></>
          )}

          <div className="fontWeightBold">
            {/* Trademark start */}
            {sysInfo?.MP_COMP?.KB_SYOHYO === FLAG_BOOLEAN.TRUE ? (
              <div className="tradeMark-container">
                <div className="tradeMark-title title-common">{t('compliance.lblTradeMark')}</div>
                <div className="tradeMark-checkbox content-common">
                  <CustomCheckbox
                    disabled={checkboxLast}
                    checked={Boolean(Number(tradeMark))}
                    value={tradeMark}
                    onChange={(e) => handleChangeCheckBox('KB_CMPL', e)}
                    className="checkbox"
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            {/* Trademark end */}
            {/* Confirmation start */}
            {sysInfo?.MP_COMP?.KB_KANNAI === FLAG_BOOLEAN.TRUE ? (
              <div className="confirm-container">
                <div className="confirm-title title-common">{t('compliance.lblPlanConfirm')}</div>
                <div className="confirm-checkbox content-common">
                  <CustomCheckbox
                    disabled={checkboxLast}
                    checked={Boolean(Number(confirm))}
                    value={confirm}
                    onChange={(e) => handleChangeCheckBox('KB_KINFO', e)}
                    className="checkbox"
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            {/* Confirmation end */}
            {/* Approval start */}

            <div className="approval-container">
              <div className="approval-title title-common">{t('compliance.lblApproval')}</div>
              <div className="approval-wrapper content-common">
                <div className="approval-checkbox">
                  <CustomCheckbox
                    checked={Boolean(Number(approval))}
                    value={approval}
                    id="lastCheckbox"
                    disabled={enableLastCheckbox}
                    onChange={(e) => {
                      toggleLastCheckbox()
                      handleChangeCheckBox('KB_APPR', e)
                    }}
                    className="checkbox"
                  />
                </div>
                <div className="approval-date-container fontWeightNormal">
                  <div className="approval-date-title">{t('compliance.lblApprovalDate')}</div>
                  <div className="approval-date">{dateApproval}</div>
                </div>
              </div>
            </div>

            {/* Approval end */}
          </div>

          {/* Button Update Start */}
          <div className={`buttonSpace footerButtonGroup`}>
            <Button
              className="button submit-button"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              disabled={!canSubmit}
            >
              {t('commonComponent.button.btnSubmit')}
            </Button>
            <Button className="button clear-button" variant="outlined" onClick={handleCancelButton}>
              {t('commonComponent.button.btnCancel')}
            </Button>
          </div>

          {/* Button Update End */}
        </div>
      )}
    </>
  )
}

export default FormCompliance
