import './Sidebar.css'

import { IMAGES } from 'assets'
import { Image } from 'components/commons'
import { PAGE_LOGO } from 'constants/constant'
import React from 'react'
import { NavLink } from 'react-router-dom'

import companyLogo from '../../../assets/img/logo_on.gif'
import MenuLink from './MenuLink/MenuLink'
import SidebarHeader from './SidebarHeader/SidebarHeader'
function Sidebar({ mobileOpen, handleSidebarToggle, routers, rendererRoute }) {
  // let history = useHistory()

  const CompanyLogo = () => <img src={companyLogo} alt="Company Logo Icon" className="sidebar-logo" />
  // const Download = () => <img src={IMAGES.ICON.DOWNLOAD_ICON} alt="Download Icon" className="sidebar-arrowIcon"></img>
  // const DownloadRed = () => (
  //   <img src={IMAGES.ICON.DOWNLOAD_ICON} alt="Download Red Icon" className="sidebar-arrowIcon"></img>
  // )

  return (
    <div className={`wrapper__side-bar ${!mobileOpen ? 'side-bar' : 'hide-sideBar'}`}>
      {/* Company */}
      <div className="company-container">
        <div className="company-logo">
          <NavLink to={'/customer/portal'}>
            <CompanyLogo />
          </NavLink>
        </div>
      </div>
      {/* Header */}
      <div className="sidebar-header">
        <div className="header-text">{PAGE_LOGO}</div>
        <div className="closeMenu-container">
          <Image src={IMAGES.ICON.COLAPSE_MENU} className="sidebar-icon" onClick={handleSidebarToggle} />
        </div>
      </div>
      {/* Menu */}
      <div className="menu-container">
        {routers.map((route, key) => {
          if (route.ext) return null
          if (route.type)
            return (
              <SidebarHeader
                key={key}
                icon={<Image src={IMAGES.ICON.MENU[route.icon]} className="sidebar-icon" />}
                text={route.name}
                pathTo={route.path !== '/' ? route.layout + route.path : ''}
              />
            )
          return (
            <div className="menu-wrapper" key={key}>
              {/* <ul className="menu-content">
                <NavLink to={route.layout + route.path} className="nav-link text-link" activeClassName="active">
                  <div className="link-icon">
                    {history.location.pathname.indexOf(route.layout + route.path) > -1 ? <DownloadRed /> : <Download />}
                  </div>
                  <div
                    className={
                      history.location.pathname.indexOf(route.layout + route.path) > -1
                        ? 'link-name active-link'
                        : 'link-name'
                    }
                  >
                    {route.menuName || ''}
                  </div>
                </NavLink>
              </ul> */}
              <MenuLink rendererRoute={rendererRoute} route={route} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar
