import Auth from '@aws-amplify/auth'
import { showAlertAuth } from 'actions/userActions'
import axios from 'axios'
import baseUrlAxios from 'commons/baseUrl'
import {
  API_CHECK_EXISTED_FILE,
  API_DOWNLOAD_CSV,
  API_GENERATE_PRESIGNED_URL_S3,
  API_GET_FILE_FROM_S3,
  API_GET_USER_DETAIL,
} from 'constants/apiPath'
import { FLAG_BOOLEAN } from 'constants/constant'
// S3_TEMPLATE
import { store } from 'store'

import { upload } from './s3'
const { dispatch } = store
let loginAlert = false

// format datetime
const formatDateWithPadding = (date, format) => {
  try {
    if (date) {
      if (typeof date == 'string') {
        date = new Date(date)
      }
      format = format.replace(/yyyy/g, date.getFullYear())
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
      format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
      format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
      format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
      return format
    }
    return ''
  } catch (error) {
    console.warn(error, 'error format date')
  }
}

// format datetime
const formatCurrentDateJPWithPadding = (format) => {
  let date = new Date(nowDateTime('+9'))

  format = format.replace(/yyyy/g, date.getFullYear())
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))

  return format
}

const nowDateTime = (offset) => {
  let d = new Date()
  let utc = d.getTime() + d.getTimezoneOffset() * 60000
  let nd = new Date(utc + 3600000 * offset)

  return nd
}

const findWeekDay = (year, month, day) => {
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  var weekDay = days[new Date(year, --month, day).getDay()]
  switch (weekDay) {
    case 'Sunday':
      return '?'
    case 'Monday':
      return '?'
    case 'Tuesday':
      return '?'
    case 'Wednesday':
      return '?'
    case 'Thursday':
      return '?'
    case 'Friday':
      return '?'
    case 'Saturday':
      return '?'
    default:
      return ''
  }
}

const findWeekDayJP = (date) => {
  const weekday = ['月', '火', '水', '木', '金', '土', '日']
  const day = new Date(date).getDay()
  if (day === 0) {
    return weekday[6]
  }
  return weekday[day - 1]
}

const parseDateTime = (date) => {
  try {
    return date.toString().replace(/[:,. -]/g, '')
  } catch (e) { }
}

const addDays = (date, days) => {
  try {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  } catch (err) {
    return new Date()
  }
}

const checkEmail = (email) => {
  return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
}

function validURL(str) {
  // var pattern = new RegExp(
  //   '^(https?:\\/\\/)?' + // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //     '(\\#[-a-z\\d_]*)?$',
  //   'i'
  // ) // fragment locator
  var pattern = new RegExp(
    '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  )

  return !!pattern.test(str)
}

const getFileFromS3 = async (bucketName, filePath) => {
  const apiUrl = process.env.REACT_APP_API_ENPOINT + API_GET_FILE_FROM_S3
  const token = await getCurrentToken()
  const headers = { Authorization: token || '' }
  const data = {
    bucketName: bucketName || '',
    filePath: filePath,
  }
  try {
    const response = await axios.post(apiUrl, data, { headers })
    if (response?.data?.data) {
      return response.data.data
    } else {
      throw new Error(response.message)
      // return false
    }
  } catch (e) {
    throw e
  }
}

/**

 * Download file handle
 * @param {String} file
 * @return {void}
 */
// const handleDownload = async (file, fileName, contentType) => {
//   if (file instanceof File) {
//     const objectURL = window.URL.createObjectURL(file)
//     const link = document.createElement('a')
//     link.href = objectURL
//     link.download = file.name
//     document.body.appendChild(link)
//     link.click()
//     document.body.removeChild(link)
//     return true
//   }
//   async function execute() {
//     const base64File = convertArrayBufferToBase64(file)
//     const url = `data:${contentType};base64,` + base64File
//     const link = document.createElement('a')
//     link.href = url
//     link.download = fileName
//     document.body.appendChild(link)
//     link.click()
//     document.body.removeChild(link)
//   }
//   try {
//     if (file) {
//       await execute()
//     } else {
//       return false
//     }
//   } catch (e) {
//     return false
//   }
//   return true
// }

const handleDownload = (file, fileName) => {
  if (file instanceof File) {
    const objectURL = window.URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = objectURL
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return true
  }
  async function execute() {
    const fetchUrl = file
    axios({
      url: fetchUrl,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.warn('file not found in system', error)
      })
  }
  try {
    if (file) {
      execute()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
  return true
}

const convertArrayBufferToBase64 = (arrayBuffer) => {
  let binary = ''
  const bytes = new Uint8Array(arrayBuffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  const file = window.btoa(binary)
  return file
}

const getCurrentToken = async () => {
  const ss = await Auth.currentSession().catch((e) => {
    if (!loginAlert) {
      loginAlert = true
      dispatch(showAlertAuth())
      return
    }
  })
  const token = ss ? ss?.getIdToken()?.getJwtToken() : ''
  return token
}
// const checkExistedFileInS3 = async (fileName) => {
//   const checkExistedFileUrl = process.env.REACT_APP_API_ENPOINT + API_CHECK_EXISTED_FILE
//   const token = await getCurrentToken()
//   const headers = { Authorization: token || '' }
//   const data = {
//     bucketName: process.env.REACT_APP_S3_DATA_BUCKET,
//     filePath: S3_TEMPLATE + fileName,
//   }
//   const response = await axios.post(checkExistedFileUrl, data, { headers })
//   return response.data
// }

const checkExistedFileInS3 = async (data) => {
  const checkExistedFileUrl = process.env.REACT_APP_API_ENPOINT + API_CHECK_EXISTED_FILE
  const token = await getCurrentToken()
  const headers = { Authorization: token || '' }

  const response = await axios.post(checkExistedFileUrl, data, { headers })
  return response.data
}

/**
 * Download xlsx template file handle
 * @param {String} file
 * @return {void}
 */
const handleDownloadSingleFile = async (fetchUrl) => {
  await axios({
    url: fetchUrl,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = fetchUrl.split('/').pop()
      link.download = decodeURI(fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error) => {
      console.warn(error)
    })
}

const handleDownloadSingleFileDataDownload = async (fileUrl, fileName, updateDataDownload) => {
  // const token = await getCurrentToken()
  // const headers = { Authorization: token || '', 'Access-Control-Allow-Origin': '*' }
  function execute() {
    axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
      // headers: headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        // const fileName = fetchUrl.split('/').pop()
        link.download = decodeURI(fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return true
      })
      .catch((error) => {
        return false
      })
  }
  execute()
}

const getPreSignedUrlS3 = async (bucketName, filePath) => {
  const apiUrl = process.env.REACT_APP_API_ENPOINT + API_GENERATE_PRESIGNED_URL_S3
  const token = await getCurrentToken()
  const headers = { Authorization: token || '' }
  const data = {
    bucketName: bucketName || '',
    filePath: filePath,
  }
  try {
    const response = await axios.post(apiUrl, data, { headers })
    if (response?.data?.data) {
      return response.data.data
    }
  } catch (e) {
    return null
  }
}

const checkFileExist = async (fetchUrl, setPreviewErrorMessage, errorMessage, component) => {
  try {
    const res = await axios({
      url: fetchUrl,
      method: 'GET',
      responseType: 'blob',
    })
    if (res.status === 200) {
      return component
    }
  } catch (err) {
    setPreviewErrorMessage(errorMessage)
  }
}
const formatNumberData = (number) => {
  const lastIndexOfDot = number.toString().lastIndexOf('.')

  if (lastIndexOfDot !== -1) return number.toString().replaceAll(/\d(?=(\d{3})+\.)/g, '$&,')
  else {
    return number.toString().replaceAll(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}
const handleDownload2 = (params, i18n) => {
  const {
    screenName,
    searchText,
    date,
    dateType,
    week,
    approval,
    hqConfirm,
    kbEdit,
    cdBranch,
    cdCust,
    folderName,
    undownloaded,
    kySRC,
  } = params
  const url = process.env.REACT_APP_API_ENPOINT + API_DOWNLOAD_CSV
  async function execute() {
    // const fetchUrl = S3_BUCKET_URL + file
    const ss = await Auth.currentSession().catch((e) => {
      if (!loginAlert) {
        loginAlert = true
        dispatch(showAlertAuth())
        return
      }
    })
    const token = ss ? ss?.getIdToken()?.getJwtToken() : ''
    axios({
      url: url,
      method: 'POST',
      responseType: 'blob',
      data: {
        screenName: screenName,
        searchText: searchText,
        date: date,
        dateType: dateType,
        week: week,
        approval: approval,
        hqConfirm: hqConfirm,
        kbEdit: kbEdit,
        cdBranch: cdBranch,
        cdCust: cdCust,
        folderName: folderName,
        undownloaded: undownloaded,
        kySRC: kySRC,
      },
      headers: { Authorization: token || '' },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv;charset=utf-8,' }))
        const link = document.createElement('a')
        const encodedUrl = encodeURI(url)
        const currentDate = getCurrentDateNoPadding()
        const fileName = i18n.t(`screenNames.${screenName}`, {
          downloadDate: currentDate,
        })
        link.setAttribute('href', encodedUrl)
        link.setAttribute('download', `${fileName}`)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.warn('file not found in system', error)
      })
  }
  execute()
  return false
}

/**
 * Handle upload image s3
 * @param key
 * @returns {Promise<void>}
 */
const uploadFile = async (filesUpload, key) => {
  filesUpload.map(async (file) => {
    try {
      await upload(file, `${key}/${file.name}`)
    } catch (e) {
      console.warn('Please check your file uploaded!', e)
    }
  })
}

/**
 * Handle upload template file to s3
 * @param key
 * @returns {Promise<void>}
 */
const uploadTemplateFile = async (file, key) => {
  try {
    await upload(file, `${key}/${file.name}`)
  } catch (e) {
    console.warn('Please check your file uploaded!', e)
  }
}

/**
 * Break to new lines when reach max character
 * @params str: {String}, maxCharacter: {Number}
 * @return {String}
 */
const addNewlines = (str = '', maxCharacter) => {
  var result = ''
  while (str.length > 0) {
    result += str.substring(0, Number(maxCharacter)) + '\n'
    str = str.substring(Number(maxCharacter))
  }
  return result
}
const eventKeydown = (e) => {
  if (e.shiftKey && e.code === 'Tab') {
    if (e.keyCode === 229) {
      e.preventDefault()
    } else {
      let form = e.target.form

      let array = [].slice
        .call(form.elements)
        .filter((x) => x.disabled === false)
        .filter((x) => x.style.visibility !== 'hidden')
      array.forEach((el, index, object) => {
        if (el.getAttribute('type') === 'file') {
          object.splice(index, 1)
        }
      })
      let index = Array.prototype.indexOf.call(array, e.target)

      if (index > 0) {
        array[index - 1].focus()
        e.preventDefault()
      } else {
        index = array.length
        array[index - 1].focus()
        e.preventDefault()
      }
    }
  } else if (e.code === 'Tab') {
    if (e.keyCode === 229) {
      e.preventDefault()
    } else {
      let form = e.target.form

      let array = [].slice
        .call(form.elements)
        .filter((x) => x.disabled === false)
        .filter((x) => x.style.visibility !== 'hidden')
      array.forEach((el, index, object) => {
        if (el.getAttribute('type') === 'file') {
          object.splice(index, 1)
        }
      })
      let index = Array.prototype.indexOf.call(array, e.target)

      if (index < array.length - 1) {
        array[index + 1].focus()
        e.preventDefault()
      } else {
        index = -1
        array[index + 1].focus()
        e.preventDefault()
      }
    }
  }
}
/**
 * Remove excess character if reach limit length
 * @params string: {String}, limit: {Number}, showDot: {Boolean}
 * @return {String}
 */
const removeExcessCharacters = (string = '', limit = 110, showDot = false) => {
  let result = string.substring(0, Math.min(string.length, Number(limit)))
  if (showDot && string.length >= Number(limit)) {
    result = result + '...'
  }
  return result
}
const reRenderMessageSplitDot = (textLine1 = '', textLine2 = '', textLine3 = '') => {
  return `<div class="message__pre-text"><div>
  ${textLine1} </div><div>
  ${textLine2}</div></div>
  ${textLine3}</div></div>`
}

const getUserInfo = async (userInfo) => {
  try {
    const res = await baseUrlAxios.post(API_GET_USER_DETAIL, JSON.stringify({ userId: userInfo.ID_USER }))
    return res?.data?.data
  } catch (e) {
    return {}
  }
}

/**
 * Get current date: YYYY/MM/DD
 * @return {String}
 */
const getCurrentDate = () => {
  let today = new Date()
  let dd = String(today.getDate()).padStart(2, '0')
  let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  let yyyy = today.getFullYear()

  today = yyyy + '-' + mm + '-' + dd
  return today
}

/**
 * Get current date: YYYYMMDD
 * @return {String}
 */
const getCurrentDateNoPadding = () => {
  let today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = today.getFullYear()

  today = yyyy + mm + dd
  return today
}

/**
 * Format Csv Data To String
 * @returns string
 */
const removeDoubleQuoteCsv = (headerArr = [], data = []) => {
  let finalData = ''
  let lineBreak = '\n'
  let comma = ''
  if (data.length > 0) {
    data.forEach((el, index) => {
      let currentString = headerArr.reduce((acc, val, idx) => {
        comma = idx !== headerArr.length - 1 ? ',' : ''
        if (el.hasOwnProperty(val)) {
          acc += el[`${val}`] + comma
        } else {
          acc += '' + comma
        }
        return acc
      }, '')
      let stringFormat = currentString.replace(/(\r\n|\n|\r)/gm, '').replace(/\s*,\s*/g, ',') + lineBreak
      finalData = finalData.concat(stringFormat)
    })
  }

  return finalData
}
/**
 * Show three dot if text line > 2
 * @params string: {String}
 * @return {String}
 */
const showDot = (str) => {
  const re = /\r\n|\n\r|\n|\r/g
  let arrayOfLines = str.replace(re, '\n').split('\n')
  if (arrayOfLines.length > 2) {
    return arrayOfLines[0] + '\n' + arrayOfLines[1] + '...'
  }
  return str
}

const isIncludeSearchInput = (data = '', searchInput) => {
  return data.toLowerCase().replace(/\s/g, '').includes(searchInput.toLowerCase().replace(/\s/g, ''))
}

const getDateInfoSystem = () => {
  return (
    JSON.parse(sessionStorage.getItem('MP_DEADL')) || {
      DT_DEFAULT: '2035-12-31',
      DT_DEND: '2035-12-31',
      DT_DSTART: '2020-01-01',
    }
  )
}

const dataSearchResult = (listData = [], filteredFields = [], searchInput = '') => {
  return listData.filter((data) =>
    filteredFields.some((field) =>
      data.hasOwnProperty(field) ? isIncludeSearchInput(data[field], searchInput) : false
    )
  )
}

function isValidDate(date) {
  // eslint-disable-next-line
  var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/
  if (!regex_date.test(date)) {
    return false
  }
  var parts = date.split('-')
  var day = parseInt(parts[2], 10)
  var month = parseInt(parts[1], 10)
  var year = parseInt(parts[0], 10)

  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29
  }

  return day > 0 && day <= monthLength[month - 1]
}

const isInDateRange = (date, start, end) => {
  const checkDate = new Date(date).getTime()
  const startDate = new Date(start).getTime()
  const endDate = new Date(end).getTime()
  return checkDate >= startDate && checkDate <= endDate
}

const removeAllSpace = (string) => {
  return string.replaceAll(/\s+/g, '')
}

const shortenValue = (value, numberOfCharacter) => {
  if (value.length > numberOfCharacter) {
    return value.substring(0, numberOfCharacter).concat('...')
  }
  return value
}

const getCurrentTime = () => {
  const currentDate = new Date()
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')
  const miliseconds = String(currentDate.getMilliseconds()).padStart(2, '0')
  return `${hours}:${minutes}:${seconds}.${miliseconds}`
}

const getDateByDtToday = (dtToday) => {
  if (!dtToday || dtToday.length === 0) {
    return formatDateWithPadding(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS')
  }
  const noPaddingTime = getCurrentTime()
  return formatDateWithPadding(dtToday + ' ' + noPaddingTime, 'yyyy-MM-dd HH:mm:ss.SSS')
}

const weekCount = (year, month_number) => {
  // month_number is in the range 1..12

  const firstOfMonth = new Date(year, month_number - 1, 1)
  const lastOfMonth = new Date(year, month_number, 0)

  const used = firstOfMonth.getDay() + lastOfMonth.getDate()

  return Math.ceil(used / 7)
}

const isWeekInvalid = (date) => {
  const dateSplit = String(date).split('-')
  const year = dateSplit[0]
  const month = dateSplit[1]
  const day = dateSplit[2]
  // get number of week base on month
  const numOfweek = weekCount(year, month)
  if (Number(day) > Number(numOfweek)) {
    return true
  }
  return false
}

const getWeekNumberOfDate = (d, haveDash = false) => {
  d = new Date(String(d))
  let date = d.getDate()
  let day = d.getDay()
  let weekOfMonth = Math.ceil((date - 1 - day) / 7) + 1
  if (d.getMonth() + 1 < 10) {
    if (haveDash) {
      return `${d.getFullYear()}-0${d.getMonth() + 1}-0${weekOfMonth}`
    }
    return `${d.getFullYear()}0${d.getMonth() + 1}0${weekOfMonth}`
  } else {
    if (haveDash) {
      return `${d.getFullYear()}-${d.getMonth() + 1}-0${weekOfMonth}`
    }
    return `${d.getFullYear()}${d.getMonth() + 1}0${weekOfMonth}`
  }
}

const getWeekOfMonth = (date) => {
  const temp = new Date(String(date))
  const weekOfMonth = Math.ceil((temp.getDate() - 1 - temp.getDay()) / 7) + 1
  return `0${weekOfMonth}`
}

const createListYear = (from, to) => {
  const listYear = []
  let i = 0
  for (let year = from; year <= to; year++) {
    listYear.push({
      value: i.toString(),
      label: year.toString(),
    })
    i++
  }
  return listYear
}

const createListMonth = (from, to) => {
  const listMonth = []
  let i = 0
  for (let month = from; month <= to; month++) {
    let temp = Number(month) >= 10 ? String(month) : '0' + String(month)
    listMonth.push({
      value: i.toString(),
      label: temp,
    })
    i++
  }
  return listMonth
}

const createListDtWeek = (listDtWeek, currentDtWeek) => {
  const listDtWeekOption = []

  const weekIndex = listDtWeek.findIndex((week) => week === currentDtWeek)
  if (weekIndex > -1) {
    let temp = listDtWeek[0]
    listDtWeek[0] = listDtWeek[weekIndex]
    listDtWeek[weekIndex] = temp
  } else {
    listDtWeek.unshift(currentDtWeek)
  }
  listDtWeek
    .sort((a, b) => b.localeCompare(a))
    .forEach((week, index) => {
      listDtWeekOption.push({
        value: String(index),
        label: week,
      })
    })

  return listDtWeekOption
}

const createListYmRegist = (listYmRegist, currentYmRegist) => {
  const listYmRegistOption = []
  listYmRegistOption.push({
    value: '0',
    label: currentYmRegist,
  })
  const newListYmRegist = listYmRegist.filter((el) => el !== currentYmRegist)

  for (let i = 0; i < newListYmRegist.length; i++) {
    const j = i + 1
    listYmRegistOption.push({
      value: j.toString(),
      label: newListYmRegist[i],
    })
  }
  return listYmRegistOption
}

const getSessionInfo = () => {
  return JSON.parse(localStorage.getItem('currentSession'))
}

const isKontamiExist = (kb_contami = '') => {
  return kb_contami === FLAG_BOOLEAN.TRUE ? true : false
}

const formatDtweekOptions = (dtWeek = '') => {
  let formatDate = ''
  if (dtWeek) {
    let year = dtWeek.substring(0, 4)
    let month = dtWeek.substring(4, 6)
    let week = dtWeek.substring(6)
    formatDate = year + '-' + month + '-' + week
  }
  return formatDate
}

const caculateTableWidth = (listColumnWidth = []) => {
  return listColumnWidth.reduce((prev, curr) => {
    let width = curr.split('px')
    return prev + Number(width[0])
  }, 0)
}

// eslint-disable-next-line simple-import-sort/exports
export {
  addDays,
  weekCount,
  addNewlines,
  checkEmail,
  dataSearchResult,
  findWeekDay,
  formatCurrentDateJPWithPadding,
  formatDateWithPadding,
  getCurrentDate,
  getCurrentDateNoPadding,
  getDateByDtToday,
  getDateInfoSystem,
  handleDownload,
  handleDownload2,
  handleDownloadSingleFile,
  isIncludeSearchInput,
  isInDateRange,
  isValidDate,
  parseDateTime,
  removeAllSpace,
  removeDoubleQuoteCsv,
  removeExcessCharacters,
  shortenValue,
  showDot,
  uploadFile,
  uploadTemplateFile,
  validURL,
  checkExistedFileInS3,
  isWeekInvalid,
  getWeekNumberOfDate,
  findWeekDayJP,
  checkFileExist,
  createListYear,
  createListMonth,
  getWeekOfMonth,
  formatNumberData,
  reRenderMessageSplitDot,
  getSessionInfo,
  eventKeydown,
  createListDtWeek,
  isKontamiExist,
  createListYmRegist,
  formatDtweekOptions,
  getUserInfo,
  getPreSignedUrlS3,
  convertArrayBufferToBase64,
  getFileFromS3,
  caculateTableWidth,
  handleDownloadSingleFileDataDownload,
}
