const JP_WEEK_DAYS = ['日', '月', '火', '水', '木', '金', '土']

// regex string
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
const ID_REGEX = /^[a-z0-9A-Z]+$/
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const NUMBER_ONLY_REGEX = /^[0-9\b]+$/
const FILTER_ITEMS = ['開始日', '終了日', '有効期限日', '受信日付', 'すべて']

const PORTAL_ACTIONS = ['CREATE', 'UPDATE', 'DELETE']

const ACTION_FORM = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

const KEY_CODE = {
  SPACE: 0,
}
// const FORM_KEYWORD = ['form', 'my-page', 'change-password']

const S3_BUCKET_URL = 'https://ordering-transfer-data.s3.ap-northeast-1.amazonaws.com/'
const S3_NIGHT_FUNCTION_BUCKET_URL = 'https://ordering-transfer-nightfunction.s3.ap-northeast-1.amazonaws.com/'
const S3_TEMPLATE = 'template/'
const S3_RCV = 'receive/'
const TRANSFER_DATA = 'transfer-data/'

const DATE_TYPE = {
  DEFAULT: 'DEFAULT',
  START: 'DT_START',
  END: 'DT_END',
  EXPIRED: 'DT_DEADL',
  DATE_RECEIVE: 'DT_RCV',
}
const FORM_KEYWORD = ['form', 'my-page', 'change-password', 'reissue']

const SORT_TYPE = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
}

const PORTAL_TYPES = {
  NOTICE: '0',
  TOPIC: '1',
  LINK: '2',
}

const RESPONSE_TYPE = {
  SUCCESS: 0,
  DATA_NOT_EXIST: 1,
  DATA_EXISTED: 2,
  USER_IS_EXPIRED: 3,
  USER_IS_DELETED: 4,
  ERROR: 9,
}

const SPECIAL_CELL_TYPE = {
  EMAIL: 'EMAIL',
  URL: 'URL',
  FILE: 'FILE',
  PERSON: 'PERSON',
  DATE_START: 'DATE_START',
  DATE_END: 'DATE_END',
  DATE_DEAL: 'DATE_DEAL',
  CHECK: 'CHECK',
  SORTED_START: 'SORTED_START',
  SORTED_END: 'SORTED_END',
  MAIL_ADDRESS_1: 'MAIL_ADDRESS_1',
  MAIL_ADDRESS_2: 'MAIL_ADDRESS_2',
  HHMM: 'HHMM',
  NU_SIZE: 'NU_SIZE',
  NU_COUNT: 'NU_COUNT',
  PL_INFO: 'PL_INFO',
  ADD_INFO: 'ADD_INFO',
  PRODUCT_RCV_INFO: 'PRODUCT_RCV_INFO',
  REDIRECT_DELIVERY_DATA_DETAIL: 'REDIRECT_DELIVERY_DATA_DETAIL',
  CHECK_BOX: 'CHECK_BOX',
  PREVIEW: 'PREVIEW',
  DOWNLOAD: 'DOWNLOAD',
  DT_REGIST: 'DT_REGIST',
  KB_DL: 'KB_DL',
  DT_DL: 'DT_DL',
}

const ID_CELL_TYPE = ['ID_USER', 'CD_BRANCH', 'CD_CUST', 'CD_ALRG', 'ID_DATA', 'CD_KEY', 'ID_MAIL']

const CELL_TYPE_DATA_TRANSFER = {
  NU_WAIT: 'NU_WAIT',
  NU_ALERT: 'NU_ALERT',
  KB_CYCLE: 'KB_CYCLE',
  KB_FL_FORMAT: 'KB_FL_FORMAT',
  KB_DELETE: 'KB_DELETE',
  EXCEP_NUMBER: 'EXCEP_NUMBER',
}
const UNIT = {
  QUANTITY: '件',
  MINUTES: '分',
  DAILY: '日次',
  WEEKLY: '週次',
  MORE_THAN_ONCE: '複数回',
  TEXT: 'テキスト',
  PDF: 'PDF',
}

const DELIVERY_CYCLE = {
  DAILY: '1',
  WEEKLY: '2',
  MORE_THAN_ONCE: '3',
}

const FILE_FORMAT = {
  TEXT: '1',
  PDF: '2',
}

const TOOLBAR_TYPE = {
  PREVIEW: 'PREVIEW',
  ADD: 'ADD',
  SEARCH: 'SEARCH',
  SEARCH_LEFT: 'SEARCH_LEFT',
  FILTER_LOGO: 'FILTER_LOGO',
  FILTER_DATE: 'FILTER_DATE',
  CALENDAR: 'CALENDAR',
  END_CALENDAR: 'END_CALENDAR',
  TIME_LABEL: 'TIME_LABEL',
  CSV: 'CSV',
  EXPIRE_DATE: 'EXPIRE_DATE',
  COMPLIANCE_CHECKBOX: 'COMPLIANCE_CHECKBOX',
  APPROVAL_SELECT: 'APPROVAL_SELECT',
  HEAD_QUARTERS_CONFIRM_SELECT: 'HEAD_QUARTERS_CONFIRM_SELECT',
  SENDING_DATE: 'SENDING_DATE',
  FOLDER_SELECT: 'FOLDER_SELECT',
  DOWNLOAD: 'DOWNLOAD',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_DISABLE: 'CHECKBOX_DISABLE',
  TURN_BACK: 'TURN_BACK',
  YEAR_MONTH_CALENDAR: 'YEAR_MONTH_CALENDAR',
  DAY_COUNT_SELECT: 'DAY_COUNT_SELECT',
}

const CD_KEY = {
  SETSTART: 'SETSTART',
  PORTAL: 'PORTAL',
  KBDEL: 'KBDEL',
  FORMAT: 'FORMAT',
  CYCLE: 'CYCLE',
  APRVL: 'APRVL',
  CNFM: 'CNFM',
}

const CD_ALRG = {
  MIN: 1,
  MAX: 98,
}

const CD_LENGTH = {
  ALRG: 2,
}

const EXCEL_TEMPLATE_FORM = {
  TEMP_FILE: 'TEMPLATE_FILE',
  TEST_CSV_FILE: 'TEST_CSV_FILE',
}

const ALLERGEN_CLASSFICATION_CODE = {
  NOT_SET: '0',
  HAVE_ALLERGEN: '1',
  NOT_APPLY: '98',
  NO_ALLERGEN: '99',
}

const KONTAMI_CLASSFICATION_CODE = {
  NOT_SET: '0',
  HAVE_KONTAMI: '1',
  NOT_APPLY: '98',
  NO_KONTAMI: '99',
}

const COMPLIANCE_TYPE = {
  KONTAMI: 'KONTAMI',
  ALLERGEN: 'ALLERGEN',
}

const SCREEN_NAME = {
  MYPAGE: 'mypage',
  CHANGE_PASSWORD: 'change_password',
}
const PAGE_LOGO = '情報配信サイト'
const FLAG_BOOLEAN = {
  TRUE: '1',
  FALSE: '0',
}

const LIST_COMPLIANCE_TYPE = {
  ALLERGEN_BEFORE: 'ALLERGEN_BEFORE',
  ALLERGEN_AFTER: 'ALLERGEN_AFTER',
  KONTAMI_BEFORE: 'KONTAMI_BEFORE',
  KONTAMI_AFTER: 'KONTAMI_AFTER',
}

const CHARACTER_WIDTH = 11.12
const RIGHT_LEFT_PADDING = 30
const BRANCH_CHARACTER = 3
const MAX_CHARACTER = 10
const TABLE_WIDTH = 900

export {
  ACTION_FORM,
  ALLERGEN_CLASSFICATION_CODE,
  BRANCH_CHARACTER,
  CD_ALRG,
  CD_KEY,
  CD_LENGTH,
  CELL_TYPE_DATA_TRANSFER,
  CHARACTER_WIDTH,
  COMPLIANCE_TYPE,
  DATE_TYPE,
  DELIVERY_CYCLE,
  EMAIL_REGEX,
  EXCEL_TEMPLATE_FORM,
  FILE_FORMAT,
  FILTER_ITEMS,
  FLAG_BOOLEAN,
  FORM_KEYWORD,
  ID_CELL_TYPE,
  ID_REGEX,
  JP_WEEK_DAYS,
  KEY_CODE,
  KONTAMI_CLASSFICATION_CODE,
  LIST_COMPLIANCE_TYPE,
  MAX_CHARACTER,
  NUMBER_ONLY_REGEX,
  PAGE_LOGO,
  PASSWORD_REGEX,
  PORTAL_ACTIONS,
  PORTAL_TYPES,
  RESPONSE_TYPE,
  RIGHT_LEFT_PADDING,
  S3_BUCKET_URL,
  S3_NIGHT_FUNCTION_BUCKET_URL,
  S3_RCV,
  S3_TEMPLATE,
  SCREEN_NAME,
  SORT_TYPE,
  SPECIAL_CELL_TYPE,
  TABLE_WIDTH,
  TOOLBAR_TYPE,
  TRANSFER_DATA,
  UNIT,
}
